import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Scale`}</h1>
    <p>{`A primary feature and design assumption of the pol.is methodology is scale, ie., that 100,000 people can participate fully and effectively, and all parts of the method continue to work as if 100 people were enaging.`}</p>
    <p>{`The most constrained bit of the method are the total number of comments. Participants can scale functionally endlessly, but if there are 10,000 comments, `}<a parentName="p" {...{
        "href": "/sparsity",
        "title": "sparsity"
      }}>{`sparsity`}</a>{` will be high.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      